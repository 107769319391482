import React from "react";
import { graphql, compose } from "react-apollo";
import { PlanPicker, Plans } from "./plan";
import { AddCardForm, Mutations as PaymentMutations } from "./payment";
import { Mutations as SubscriptionMutations } from "./subscription";
import Spinner from "../spinner";

class _PlanOnboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlan: undefined,
      selectedPlanNickname: props.selectedPlanNickname,
      billingSource: props.billingSource
    };
  }

  handlePlanChange = plan => {
    this.setState({ selectedPlan: plan });
  };

  handleSourceAdded = async source => {
    const paymentResponse = await this.props.addSource({
      variables: { sourceId: source.id }
    });

    console.log(paymentResponse);

    const subscriptionResponse = await this.props.addSubscription({
      variables: {
        planNickname: this.state.selectedPlan.nickname
      }
    });

    console.log(subscriptionResponse);

    if (this.props.onComplete) {
      this.props.onComplete({
        plan: this.state.selectedPlan,
        source
      });
    }
  };

  render() {
    return (
      <div style={{ minWidth: 350 }}>
        <div
          style={{
            marginBottom: 32
            // paddingBottom: 12,
            // borderBottom: "1px solid #EEE"
          }}
        >
          <h2 style={{ marginBottom: 12 }}>Subscription Plan</h2>
          <Plans>
            {({ plans, loading, error }) =>
              loading ? (
                <Spinner />
              ) : (
                <PlanPicker
                  plans={plans || []}
                  default={plans && plans.find(p => p.nickname === "Startup")}
                  onSelect={this.handlePlanChange}
                />
              )
            }
          </Plans>
        </div>

        <div>
          <h2 style={{ marginBottom: 12 }}>Payment Information</h2>
          <AddCardForm
            buttonText={"Get Started"}
            onSourceCreated={this.handleSourceAdded}
          />
        </div>
      </div>
    );
  }
}

export const PlanOnboarding = compose(
  graphql(PaymentMutations.addSource, { name: "addSource" }),
  graphql(SubscriptionMutations.addSubscription, { name: "addSubscription" })
)(_PlanOnboarding);
