// import styled from 'styled-components'
import React from "react";
import DeveloperAPI from "../services/DeveloperAPIClient";
import { navigate } from "gatsby";
import { Link } from "@reach/router";
import Layout from "../components/layout/auth";
import { ErrorMessage, Form, Formik } from "formik";
import { FormErrorContainer } from "../components/forms";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { getQueryParams } from "../utils/navigation";

import { PlanOnboarding } from "../components/billing/onboarding";

/**
 *
 * TODO:
 * - TOS
 * - header callout
 */

class Signup extends React.Component {
  constructor(props) {
    super(props);

    let plan = undefined;
    if (typeof window !== "undefined" && window && window.location) {
      plan = getQueryParams(window.location.search).plan;
    }

    this.state = {
      redirectToReferrer: false,
      errors: null,
      plan,
      createdAccount: DeveloperAPI.isAuthenticated(),
      selectedPlan: !!plan,
      addedPaymentMethod: false
    };
  }

  componentDidMount() {
    if (
      this.state.plan === undefined &&
      typeof window !== "undefined" &&
      window &&
      window.location
    ) {
      this.setState({ plan: getQueryParams(window.location.search).plan });
    }
  }

  createAccount = async (
    { email, password, company, selectedPlanId },
    { setSubmitting, setFieldError }
  ) => {
    try {
      await DeveloperAPI.signup({
        email,
        password
        // company,
        // plan: selectedPlanId
      });
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      if (error.networkError) {
        this.setState({ errors: [{ message: error.message }] });
      } else if (error.server) {
        this.setState({
          errors: [{ message: "Server error. Please try again later." }]
        });
      } else if (error.client) {
        const clientError = error.data;
        if (clientError.errors && clientError.errors.email) {
          setFieldError("email", clientError.errors.email);
        } else {
          this.setState({
            errors: [{ message: "Unknown client login error" }]
          });
        }
      } else {
        this.setState({ errors: [{ message: "Unknown error" }] });
      }
      setSubmitting(false);
      return;
    }

    await DeveloperAPI.login(email, password);
    setSubmitting(false);
    this.setState({ createdAccount: true });
  };

  validate({ email, password }) {
    let errors = {};
    if (!email || email.trim() === "") {
      errors.email = "Email is a required field";
    }
    if (!password || password.trim() === "") {
      errors.password = "Password is a required field";
    }
    return errors;
  }

  render() {
    /**
     * Public routes need to be in both the router and in stand alone files. Otherwise, what
     * happens is that the standalone pages won't be accessible from the router or by directly
     * going to the page
     * -- Vinay 11/20/2018
     */
    // const {from} = this.props.location.state || {from: {pathname: "/"}};
    if (this.state.redirectToReferrer) {
      navigate("/", { replace: true });
      return null;
    }

    return (
      <Layout
        title="Sign up"
        footer={
          this.state.createdAccount ? null : (
            <Link style={{ marginTop: 40, color: "#AAA" }} to={"/login/"}>
              Already have an account? Click here to log in.
            </Link>
          )
        }
      >
        {this.renderContent()}
      </Layout>
    );
  }

  renderContent() {
    if (!this.state.createdAccount) {
      return this.renderAccountForm();
    }

    if (!this.state.selectedPlan || !this.state.addedPaymentMethod) {
      return this.renderPayment();
    }
  }

  renderAccountForm() {
    return (
      <>
        {" "}
        {/*<h2 style={{ marginBottom: 20 }}>Create an account</h2>*/}
        <Formik
          initialValues={{
            email: "",
            password: "",
            company: "",
            selectedPlanId: this.props.selectedPlanId
          }}
          validate={this.validate}
          onSubmit={this.createAccount}
        >
          {({
            isSubmitting,
            setFieldValue,
            errors,
            values: { email, company, password, selectedPlanId },
            handleChange
          }) => (
            <Form style={{ display: "flex" }}>
              {this.props.showPlans &&
                this.props.plans &&
                this.props.plans.length && (
                  <>
                    <div>
                      {this.props.plans.map(
                        ({ id, name, description, price }) => (
                          <div
                            onClick={() => setFieldValue("selectedPlanId", id)}
                            key={id}
                            style={{
                              cursor: "pointer",
                              padding: 10,
                              margin: 5,
                              border: `2px solid ${
                                id === selectedPlanId ? "#ffa94c" : "#EEE"
                              }`,
                              borderRadius: 8
                            }}
                          >
                            <h3>{name}</h3>
                            <p>{description}</p>
                            <h4>{price}</h4>
                          </div>
                        )
                      )}
                    </div>
                    <div
                      style={{
                        borderRight: "1px solid #EEE",
                        marginLeft: 30,
                        marginRight: 30
                      }}
                    />
                  </>
                )}

              <div
                style={{
                  maxWidth: 300,
                  width: 300,
                  minWidth: 150
                  // marginLeft: 30
                }}
              >
                {/*<FormGroup label={'Company'} labelFor={'company'}>*/}
                {/*  <InputGroup*/}
                {/*    placeholder={'Company'}*/}
                {/*    large*/}
                {/*    id={'company'}*/}
                {/*    type={'text'}*/}
                {/*    autoComplete={'company'}*/}
                {/*    name={'company'}*/}
                {/*    value={company}*/}
                {/*    onChange={handleChange}*/}
                {/*  />*/}
                {/*</FormGroup>*/}
                {/*<ErrorMessage name="company" component={FormErrorContainer} />*/}
                <FormGroup label={"Email"} labelFor={"email"}>
                  <InputGroup
                    placeholder={"Email"}
                    large
                    id={"email"}
                    type={"email"}
                    autoComplete={"email"}
                    name={"email"}
                    value={email}
                    onChange={handleChange}
                  />
                </FormGroup>
                <ErrorMessage name="email" component={FormErrorContainer} />

                <FormGroup label={"Password"} labelFor={"password"}>
                  <InputGroup
                    placeholder={"Password"}
                    large
                    type={"password"}
                    autoComplete={"password"}
                    id={"password"}
                    name={"password"}
                    value={password}
                    onChange={handleChange}
                  />
                </FormGroup>
                <ErrorMessage name="password" component={FormErrorContainer} />
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  large
                  fill
                >
                  Create Account
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }

  renderPayment() {
    return (
      <div>
        <PlanOnboarding
          onComplete={() => {
            navigate("/");
          }}
        />
      </div>
    );
  }
}

export default Signup;
