

export const getQueryParams = queryString => {
  let query = {}
  const parts = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&')

  for (let p of parts) {
    const [name, val] = p.split('=')
    query[decodeURIComponent(name)] = decodeURIComponent(val || '')
  }
  return query
}
